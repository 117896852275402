import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const router = new VueRouter({
  routes: [{
      path: '/',
      redirect: 'home',
      name: 'Home',
      component: () => import('@/views/home/index'),
      children: [{
          path: 'home',
          component: () => import('@/views/home/mycontent/index'),
          meta: {
            title: "主页",
          },
        }, {
          path: 'to_work/:work_type',
          props: true,
          component: () => import('@/views/mybody/index'),
          meta: {
            title: "程序页面",
          },
        }, {
          path: 'converter/:work_type',
          props: true,
          component: () => import('@/views/mybody/html'),
          meta: {
            title: "程序页面",
          },
        }, {
          path: 'upload/:work_type',
          props: true,
          component: () => import('@/views/mybody/double_upload'),
          meta: {
            title: "程序页面",
          },
        }, {
          path: 'login',
          component: () => import('@/views/mybody/login'),
          meta: {
            title: "登录界面",
          },
        }, {
          path: '/done/:work_type',
          props: true,
          component: () => import('@/views/mybody/upload_done'),
          meta: {
            title: "上传成功处理",
          }
        },
        {
          path: 'download',
          component: () => import('@/views/mybody/upload_done/download.vue'),
          meta: {
            title: "下载页面"
          }
        }

      ]
    }, {
      path: '/user',
      redirect: 'info',
      name: 'user',
      component: () => import('@/views/home/index'),
      children: [{
        path: '/',
        redirect: 'info',
        component: () => import('@/views/user/index'),
        children: [{
            path: 'info',
            component: () => import('@/views/user/userinfo'),
            meta: {
              title: "个人中心",
            },
          },
          {
            path: 'order',
            component: () => import('@/views/user/order'),
            meta: {
              title: "个人中心",
            },
          },
          {
            path: 'address',
            component: () => import('@/views/user/address'),
            meta: {
              title: "个人中心",
            },
          }
        ]
      }]
    },
    {
      path: '/test/:work_type',
      props: true,
      component: () => import('@/views/mybody/html')
    }
  ]
})

router.beforeEach((to, from, next) => {
  let url = to.path
  let work_type = to.params.work_type
  let way = [
    'html_to_pdf',
    'html_to_img'
  ]
  if (url.includes('to_work') && way.includes(work_type)) {
    router.replace('/converter/' + to.params.work_type)
  }
  let way_2 = [
    'img_to_pdf',
    'pdf_multiffilee',
    
  ]
  if (url.includes('to_work') && way_2.includes(work_type)) {
    router.replace('/upload/' + to.params.work_type)
  }
  next()
})

router.afterEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${Vue.prototype.website_name} - ${to.meta.title}`
  }
})

export default router